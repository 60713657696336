import autoView from "./components/RespondBox";//响应式盒子
import custBuySwiper from "./components/CustBuySwiper";//60商家滚动组件
import countToAct from "./components/CountToAct";//数字滚动(带背景)
import { getProductInfo, getCustBuyMsg, getTarget, getTop10 } from "./api";
import getData from "./getData";
import countTo from "vue-count-to";
export default {
  components: {
    autoView,
    custBuySwiper,
    countToAct,
    countTo
  },
  data() {
    return {
      index:0,//展示第几个商品的相关信息 默认第一个
      dataList:[],//活动商品列表 -》 用于键盘上下键获取商品信息 及 判断是否最后一个（提示）
      infoImg:"",//带信息的活动商品图
      productCode:"",//商品code -》 用于请求60商家 top10 进度
      
      changeLoading:true,//切换商品的loading开关 默认开启 请求第一次关闭
      loadingControlArr:[false, false, false, false],//4项 1-> 总开关 ->切换时开 2-请求完基本信息时开 3-请求完60商家开 4-top10 5-目标达成
      // stepArr:[],//步骤条数量价格数据
      custList:[],//60商家 滚动数据
      topTenList:[],//top10 列表

      //百分比达成目标
      targetNum: 0,//目标数量
      finishNum: 0,//完成数量
      finishNumOld:0,//上次调用的完成数量
      unFinishNum: 0,//未完成数据
      unFinishNumArr:[],//未完成数据格式化后的数组['1', ',', '2', '3', '4']
      stepArr:[],//步骤条数量价格数据
      
      timer1:null,//定时器数组 1-60商家 2-top10 3-目标数据
      timer2:null,
      timer3:null,
    };
  },
  filters: {
    // 数据每个三位加，
    num: function(value) {
      // if(!value) return '0.00'
      value = value.toFixed(0);
      var intPart = Math.trunc(value);// 获取整数部分
      var intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,"); // 将整数部分逢三一断
      var floatPart = ""; // 预定义小数部分
      var value2Array = value.split(".");
      // =2表示数据有小数位
      if (value2Array.length === 2) {
        floatPart = value2Array[1].toString(); // 拿到小数部分
        if (floatPart.length === 1) { // 补0,实际上用不着
          return intPartFormat + "." + floatPart + "0";
        } else {
          return intPartFormat + "." + floatPart;
        }
      } else {
        return intPartFormat + floatPart;
      }
    }

  },
  computed: {
    finishRate(){
      // 完成率
      if(this.targetNum && this.finishNum) {
        if(this.finishNum >= this.targetNum){
          return 100
        }else{
          return parseInt((this.finishNum / this.targetNum) * 100);
        }
      }
      return 0;
    },
    isLoaing(){
      //接口报错状态下 不加载loadidng
      if(this.loadingControlArr.length == 0){
        return false;
      }
      //切换后
      if(!this.changeLoading){
        return false;
      }
      // if(this.loadingControlArr.every(item => item === false) && !this.changeLoading) {// 暂不用
      if(this.loadingControlArr.every(item => item === false)) {
        return false;
      }else{
        return true;
      }
    },
  },
  created() {
    // setInterval(this.scroll,1000)
  },
  watch: {
    index(){
      //切换活动 -》 步骤条进度清空
      this.stepArr = [];
      //开启总load开关
      this.changeLoading = true;//暂不用
      this.finishNumOld = 0;
      clearInterval(this.timer1);
      clearInterval(this.timer2);
      clearInterval(this.timer3);
    },
    finishNum(newNum, oldNum){
      //当前活动轮询时
      if(!this.changeLoading){
        this.finishNumOld = oldNum;
      }
    },
    loadingControlArr: {
      handler() {
        if(this.loadingControlArr.every(item => item === false)){
          //全部加载完毕
          this.changeLoading = false;
        }
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    //获取基本产品信息
    this.getInfo();

    //监听上下键 -》 获取数据信息
    this.keyDown();
    //屏幕自适应
    window.onresize = () => {
      this.$store.dispatch("user/changePageWidth");
      this.$store.dispatch("user/changePageHegiht");
    };
  },
  methods: {
    //数字转换中文
    changeNum(num) {

        let changeNum = ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九"];
        let unit = ["", "十", "百", "千", "万"];
        num = parseInt(num);
        let getWan = (temp) => {
          let strArr = temp.toString().split("").reverse();
          let newNum = "";
          for (var i = 0; i < strArr.length; i++) {
            newNum = (i == 0 && strArr[i] == 0 ? "" : (i > 0 && strArr[i] == 0 && strArr[i - 1] == 0 ? "" : changeNum[strArr[i]] + (strArr[i] == 0 ? unit[0] : unit[i]))) + newNum;
          }
          return newNum;
        };
        let overWan = Math.floor(num / 10000);
        let noWan = num % 10000;
        if (noWan.toString().length < 4) {
          noWan = "0" + noWan;
        }
        return overWan ? getWan(overWan) + "万" + getWan(noWan) : getWan(num);

    },
    // 监听键盘
    keyDown() {
     
      document.onkeydown = (e) => {
        //切换后没加载在数据 -》 不允许频繁请求切换
        if(this.changeLoading || this.loadingControlArr.some(item => item === true)){
          // alert(1)
          return;
        }
        //事件对象兼容
        let e1 = e || event || window.event;
        //键盘按键判断:左箭头-37;上箭头-38；右箭头-39;下箭头-40
        if (e1 && e1.keyCode == 40) {
          // 按下下箭头
          if(this.index + 1 >= this.dataList.length){
            // return this.$message.warning("没有更多活动了，尽情期待");
          }else{
            this.index ++ ;
            this.getInfo();
          }

        }
        if (e1 && e1.keyCode == 38) {
          // 上箭头
          if(this.index == 0){
            // return this.$message.warning("已是第一轮活动");
          }else{
            this.index -- ;
            this.getInfo();
          }
        }
      };
    },
    //  获取商品带信息的图片
    async getInfo() {
      let data = {
        actName: "测试活动"
      };
      // this.loadingControlArr[0] = true;
      this.$set(this.loadingControlArr,0,true)
      await getProductInfo(data).then(res => {
        // this.loadingControlArr[0] = false;
        this.$set(this.loadingControlArr,0,false)
        this.dataList = res.data.list;
        if (res.data.list[this.index]) {
          this.infoImg = res.data.list[this.index].imgUrl;
          this.productCode = res.data.list[this.index].code;
          //获取60商家数据
          this.getCustBuyMsg();
          //获取目标达成
          this.getTarget();
          //获取分公司top10数据
          this.getTop10();
        }
        
        //模拟数据
        // this.dataList = getData.productList.list
        // this.infoImg = getData.productList.list[this.index].imgUrl;
        // this.productCode = getData.productList.list[this.index].code;
      }).catch(() => {
        this.loadingControlArr = [];
      });
    },

    //获取60商家数据
    getCustBuyMsg() {
      // 初始化数据 -> 未切换商品 -> 不初始化数据
      if(this.changeLoading){
        this.custList = [];
      }
      
      let dataa = {
        actPrdId: this.productCode
      };
      this.$set(this.loadingControlArr,1,true)
      getCustBuyMsg(dataa).then(res => {
        this.$set(this.loadingControlArr,1,false)
        if(res.data && res.data.code == 0 && res.data.msg == 'success'){
          const spArr = (arr, num) => { //arr是你要分割的数组，num是以几个为一组
            let newArr = [] //首先创建一个新的空数组。用来存放分割好的数组
            for (let i = 0; i < arr.length;) { //注意：这里与for循环不太一样的是，没有i++
              
              newArr.push(arr.slice(i, i += num));
            }
            return newArr
          }
          // this.custList = [...this.custList,...spArr(res.data.list, 20)];
          // if(res.data.list.length == 0){
          //   this.custList = [];
          // }else if(JSON.stringify(this.custList) != JSON.stringify(spArr(res.data.list, 20))){
          //   //数据相同时 -》 不更新数据
          //   this.custList = spArr(res.data.list, 20);
          // }
          
          if(res.data.list.length == 0){
            this.custList = [];
          }
          else if(JSON.stringify(this.custList) != JSON.stringify(spArr(res.data.list, 10))){
            //数据相同时 -》 不更新数据

            let arr_ = []
          //模拟数据
          // if(getData.custList.length > 10 && getData.custList.length < 60){
          //   let data_ = spArr(getData.custList, 10);
          if(res.data.list.length > 10 && res.data.list.length < 30){
            let data_ = spArr(res.data.list, 10);  
            //最后一个数组有几条数据data_[data_.length - 1].length
            //最后一项 插入第一项数据
            data_[data_.length - 1] = [...data_[data_.length - 1],...data_[0].slice(0,10 - data_[data_.length - 1].length)]
            arr_ = data_;
          }else{
            //模拟数据
            // arr_ = spArr(getData.custList, 10);
            arr_ = spArr(res.data.list, 10);
          }
          //orgName 赋值最大字数 有值执行
          if(arr_[0][0].orgName){
            for (let arrItem of arr_) {
              let maxLen = 0;
              for (let i in arrItem) {
                if(arrItem[i].orgName.length > maxLen){
                  maxLen = arrItem[i].orgName.length;
                }
                //最后一项求玩最大orgName长度
                if(arrItem.length == (parseInt(i) + 1)){
                  for (let item of arrItem) {
                    item.maxLen = maxLen;
                  }
                }
              }
            }
          }
          this.custList = arr_;
            // this.custList = spArr(res.data.list, 20);
          }
          
          
          //6s + 1s 动画时间 7s -》 定时请求数据 + 
          this.timer1 = setInterval(() => {
            this.getCustBuyMsg();
            clearInterval(this.timer1);
          }, 7000);
        }
      }).catch(() => {
        this.loadingControlArr = [];
      });
    },
    //获取分公司top10数据
    getTop10() {
      // 初始化数据 -> 未切换商品 -> 不初始化数据
      if(this.changeLoading){
        this.topTenList = [];
      }
      let dataa = {
        actPrdId: this.productCode
      };
      this.$set(this.loadingControlArr,2,true)
      getTop10(dataa).then(res => {
        this.$set(this.loadingControlArr,2,false)
        if(res.data && res.data.code == 0 && res.data.list && res.data.list.length > 0){
          this.topTenList = res.data.list;
          //模拟数据
          // this.topTenList = getData.top10.list;
        }
        //3s定时请求数据
        this.timer2 = setInterval(() => {
          this.getTop10();
          clearInterval(this.timer2);
        }, 3000);
      }).catch(() => {
        this.loadingControlArr = [];
      });;
    },

    //获取达成目标数据
    getTarget() {
      //初始化数据 -> 未切换商品 -> 不初始化数据
      if(this.changeLoading){
        this.targetNum = 0;//目标数量
        this.finishNum = 0;//完成数量
        this.unFinishNum = 0;//未完成数据
        this.unFinishNumArr = [0];
      }
      let dataa = {
        actPrdId: this.productCode
      };
      this.$set(this.loadingControlArr,3,true)
      getTarget(dataa).then(res => {
        this.$set(this.loadingControlArr,3,false)
        if(res.data.data){
          const {targetNum,finishNum,unFinishNum, stepNum, stepPrice} = res.data.data;
          //模拟数据
          // const {targetNum,finishNum,unFinishNum, stepNum, stepPrice} = getData.mbdata;
          this.targetNum = targetNum;//目标数量
          this.finishNum = finishNum;//完成数量
          this.unFinishNum = unFinishNum;//未完成数据
          this.unFinishNumArr = this.$options.filters["num"](unFinishNum);//未完成数据格式化后的数组['1', ',', '2', '3', '4']
          
          //格式化步骤条数据
          this.stepArr = stepNum.split('-').map((item,i) => ({num: item, price: stepPrice.split('-')[i]}));
          // this.finishNum = Math.ceil(Math.random()*10000 + 5000); 
          //3s定时请求数据
          this.timer3 = setInterval(() => {
            this.getTarget();
            clearInterval(this.timer3);
          }, 3000);
        }
      }).catch(() => {
        this.loadingControlArr = [];
      });;
    }

  },
  beforeDestroy() {
    clearInterval(this.timer1);
    clearInterval(this.timer2);
    clearInterval(this.timer3);
    this.timer1 = null;
    this.timer2 = null;
    this.timer3 = null;
  }
};